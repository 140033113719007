/*------------------------------------------------------------------
[Table of contents]

Accessibility {Global Classes}
Fonts
Colors
Elements
Mixins

-------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond');

@import "media.scss";

/*
 * Accessibility {Global Classes}
 * --------------------------------------------------
 */

.is-vishidden, .visually-hidden { // For ADA helper text
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}


/*
 * Fonts
 * --------------------------------------------------
 */

$font-large: 2.5em;
$font-medium: 1.5em;
$font-small: 1.1em;

//For Collex (Collections and Exhibits)
$base-font: 'Helvetica Neue',Helvetica,Arial,sans-serif;
$accent-font: 'Cormorant Garamond', serif;


/*
 * Colors
 * --------------------------------------------------
 */

// Unv Color Palette

$darkgray: #767676;
$lightgray: #D6D6CE;
$darkred: #642822;
$maroon: #800000;
$crerarblue: #0F425C;
$eckhartpurple: #350E20;
$mansuetogreen: #3E3E23;
$reggreen:  #616530;
$ssaorange: #9A5324;


// Palette Variations

$mid-dark: #525252;
$darkneutral: #7B4943; //red
$midneutral: #D2CDCC; //lilac gray
$neutral: #e2ddd7;  //gray
$lightneutral: #f0eeef;
$brightpurple: #6d2f6a;
$hoveraccent: #D49464; //orange
$hovercalm: #eeefe5; //#e0e5e2
$hovercalmlight: #f6f7f2;
$active: #8A9045;  //bright green
$active-hover: #725663; //purple


// Opacity Fun Times

$transparent: rgba(67, 67, 67, 0.95);
$trans-red: rgba(109, 50, 39,0.8);
$trans-blue: rgba(21, 95, 131, 0.8);
$trans-purple: rgba(53, 14, 32, 0.8);
$trans-darkgreen: rgba(70, 71, 51, 0.8);
$trans-lightgreen: rgba(101, 105, 51, 0.8);
$trans-orange: rgba(154, 83, 46, 0.8);

// Table Color Scheme

$table-main: #ADB17D;


/*
 * Elements
 * --------------------------------------------------
 */

$divider: 1px solid $darkgray;

.nopadding {  // For use when do not want to wrap in row 
   padding-right: 0;
   padding-left: 0;
   margin-right: 0;
   margin-left: 0;
}

.btn-morelink {  // "View more ..." link for sidebar
  font-size: 0.9em;
  color: $crerarblue;
  background: $lightneutral;
  border: 1px solid $lightgray;
  border-radius: 0;
  padding: 6px 12px;
  margin-bottom: 10px;
  &:after {
    content: "\0020\f101";
    font-family: FontAwesome;
  }
  &:hover {
    background: #d8b9b7;
    color: $maroon; 
  }
}

.distinct-rightside {
  background-color: $hovercalmlight;
  margin: 10px 0;
  padding: 15px 20px;
  ul {
    list-style-type: none;
    margin-bottom: 10px;
  }
  li {
  color: $brightpurple;
  }
  a {
    color: $brightpurple;
  }
  & h2 {
    color: $reggreen;
    margin: 5px 0 10px 0;
    font-size: 1.05em;
    padding-bottom: 5px;
    border-bottom: 1px solid #a0a282;
  }
  @include respond-to(medium) {
    border-top: 0;
    margin: 0;
    max-width: 350px;
  }
  @include respond-to(large) {
    max-width: 30em;
  }
}

.menu-marker {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: #642822 transparent transparent;
  border-style: solid;
  border-width: 11px;
  left: 50%;
  transform: translateX(-50%);
  top:100%;
}


/*
 * Mixins
 * --------------------------------------------------
 */

@mixin desaturate { // for images
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

@mixin center {
    display:block;
    //margin-left: auto;
    //margin-right: auto;
}

@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin borders {
    border-bottom: 1px dotted $darkgray;
}

@mixin visually-hidden { // For ADA helper text
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
