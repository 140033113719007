/*
 * Style Notes
 * --------------------------------------------------
 *
 * Custom styling for .exhibitpage, .exhibitchildpage, .jumbotron .overlaywrap, .sidebar, .collex-loc, .loc-title
 * is in collex.sccs / embedded in the head of individual web exhibit parent pages
 *
 * Basic color styling for .collex-solo, .collex-duo, .collex-trio, .collex-verso
 * is in collex.sccs / embedded in the head of individual web exhibit parent pages
 *
 */

@import "variables.scss";
@import "base.scss";


.loc-child {
	&+& {
		padding-top: 10px;
		border-top: 1px dotted #767676;
		@include respond-to(medium) {
			padding-top: initial;
			border-top: none;
		}
	}
	.loc-title~.loc-title {
		margin-top: 1.2em;
	}
}

.collex-gallery {
	border-top: 1px dotted #767676;
}

.collexbase {
	padding: 2em 1em;
	& figure {
	   @include respond-to(medium) {
		   display: flex;
	   }
	}
}

.collex-solo {
	@extend .collexbase;
	border-bottom: 1px dotted $darkgray;
	img {
		padding-bottom: 1em	;
		max-height: 40em;
		width: auto;
		@include respond-to(medium) {
		float: right;
			padding-bottom: 0;
		}

	}
}

.collex-verso {
	@extend .collex-solo;
	.modal.and.carousel {
	  position: absolute; // Needed because the carousel overrides the position property
	}
}

.duo-wrapper {
	display:flex;
	flex-wrap: wrap;
	padding: 1em;
	border-bottom: 1px dotted $darkgray;
}

.collex-duo {
	padding: 0;
	text-align: center;
	& + & {
		border-top: 1px dotted $darkgray;
		@include respond-to(small) {
			border-top: none;
			border-left: 1px dotted $darkgray;
			padding-left: 1em;
		}
	}
	figure {
	    display: inline-block;
	    text-align: left;
	    @include respond-to(small) {
	    	max-width: 90%;
	    }
	    @include respond-to(medium) {
	    	max-width: 80%;
	    }
	}
	img {
	    margin: 1.5em 1em 1.5em 0;
		@include respond-to(medium) {
		    max-height: 30em;
		    width: auto;
		}
	}
}

.collex-trio {
	padding: 1em 0 2em 0;
	border-bottom: 1px dotted $darkgray;
	img {
		float: left;
		max-width: 28%;
		height: auto;
		margin: 1em;
		@include respond-to(large) {
			max-width: 25%;
		}
	}
	figcaption {
		clear: both;
	}
}

#page-turn {
	position: absolute;
	width: 0;
	height: 0;
	right: 15px;
    border-top: 30px solid #fff;
    border-left: 30px solid #c0c0c0;
}









