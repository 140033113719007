/*------------------------------------------------------------------
[Table of contents]

Search Type Tabs
Search Field Row
Other Search Row

-------------------------------------------------------------------*/

@import "variables.scss";
@import "media.scss";

/*
 * Search Type Tabs
 * --------------------------------------------------
 */

h1.searchbox-header {
  font-size: $font-small;
  color: $darkred;
  margin: 10px 0 10px 5px;
  font-weight: 600;
  border: none;
}

.transformer-tabs {
  .main-search & {
    background-color: #fff;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 3px solid $lightgray;
    @include respond-to(smallonly) {
      color: #fff;
      display: flex;
    }
  }
  li {
    display: inline-block;
    padding: 0;
    vertical-align: bottom;
    a {
      display: inline-block;
      color: #525252;
      text-decoration: none;
      padding: 0.3em 1.5em;
      font-weight: 600;
      &.active {
        color: lighten($darkred, 20%);
        border-bottom: 3px solid transparent;
        border-bottom-color: $darkred;
        position: relative;
        bottom: -3px;
        &:after {
          @extend .menu-marker;
        }
        @include respond-to(smallonly) {
          color: $darkred;
          display: flex;
          padding-top: 0;
        }
      }
      &:hover {
        color: #C16622;
        border-bottom: 3px solid #C16622;
        position: relative;
        bottom: -3px;
      }
    }
  }
}

.tabs {
  > div {
    display: none;
    padding: 1em;
  }
  &.main-search {
    background: rgba(210,205,204, 0.5);
    background: -moz-linear-gradient(top,  rgba(214,214,206, 0.7) 20%, rgba(226,221,215, 0.6) 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(214,214,206, 0.7) 20%,rgba(226,221,215, 0.6) 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(214,214,206, 0.7) 20%,rgba(226,221,215, 0.6) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(214,214,206, 0.5)', endColorstr='rgba(226,221,215, 0.5)',GradientType=0 ); /* IE6-9 */
    margin-bottom: 10px;

  }
  > .active {
    display: block;
  }
}

.searchbody {
  background-color: transparent;
  padding: 15px 40px 20px 30px;
  border-top: 4px solid $darkred;
  @include respond-to(small) {
    border-top: none;
  }
}

/*
 * Search Field Row
 * --------------------------------------------------
 */

.search-btn-wrapper {
  @include respond-to(medium) {
    padding-right: 0;
  }
}

.btn-searchtype {
  border: none;
  @include respond-to(small) {
    min-width: 100px;
  }
  &>button {
    color: #fff;
    line-height: 1.2em;
    padding: 8px 25px 8px 10px;
  }
  &>.btn-default {
    margin-bottom: 0;
  }
}

.btn-searchtype>.dropdown-menu { //ALTS to work with javascript translation
  background-color: #5e5e5e;
  color: #fff;
  margin-top: -1px;
  border: none;
  border-top-left-radius: 0;
  & > ul {
    background-color: transparent;
    & > li > a {
      color: #fff;
      &:hover, &:focus {
        color: $maroon;
        background-color: rgba(210, 205, 204, 0.95); //#f7f1f0
        text-decoration: none;
      }
    }
  }
}

.btn-search {
  background-color: $darkred;
  color: #fff;
  width: 100%;
  font-size: $font-small;
  padding: 0.6em;
  line-height: 0.45em;
  margin-top: 0;
  border: none;
  @include respond-to(medium) {
    padding: 0.8em;
  }
}

.btn-search:hover, .btn-search:focus, .btn-search:active, .btn-searchtype>button:hover, .btn-searchtype>button:focus, .btn-searchtype>button:active, .btn-searchtype>button.btn.dropdown-toggle.btn-default {
    color: #fff;
    background-color: #5e5e5e;
  }

.dropdown-menu-right {
  margin: 0px;
  border: 0px;
  background-color: #F2F2F2;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option { // Makes select text readable
  overflow: initial;
}

/*
 * Other Search Row
 * --------------------------------------------------
 */

 .eoptions {
  padding: 0 0 0 5px;
  font-size: 0.9em;
  margin-bottom: 0;
  p {
    margin-bottom: 0;
  }
  @include respond-to(smallonly) {
    padding: 5px 0 0 5px;
  }
}

.advanced {
  @extend .eoptions;
  text-decoration: underline;
  @include respond-to(small) {
    text-align: right;
  }
}

 .searchoptions {
  color: #525252;
  padding: 10px 0 0 5px;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 0.9em;
  font-style: italic;
  p {
    margin-bottom: 0;
  }
}


