/*------------------------------------------------------------------

[Table of contents]

Page Specific Alterations
Fancy Splash Image (Jumbotron)
Content
Text
Images, Video, and other Media
Light Box Styling
Carousel
Homepage Modules / Widgets  
Workshops & Events Homepage Module
News Homepage Module
Ask A Librarian Pages
Buttons
Tables
Mini Search (For Lists / Tables)
Index List Page
Hours (Mods to LibCal Feed)
Agenda (Conferences)
Event Calendar List
Spaces
Collections & Exhibits Listing
Directory Listing
Staff Profile Page

-------------------------------------------------------------------*/

@import "variables.scss";
@import "media.scss";


/*
 * Page Specific Alterations
 * --------------------------------------------------
 */

.block-paragraph > .rich-text { // Giving Pages
  #p-3413 &, #p-3415 &, #p-3417 &, #p-3418 &, #p-3419 & {
    @include respond-to(small) {
      overflow: hidden;
    }
  }
}

.body-container {
  padding: 30px;
  max-width: 1200px;
  #p-3378 &, #p-1752 &, #p-1754 &, #p-1755 &, #p-1753 &, #p-1756 &, #p-1758 &, #p-1757 &, &.directory {  // Exception for Home pages and directory pages
    max-width: 2000px;
  }
  .sidebar ~ & {
    @include respond-to(medium) {
      padding-left: 45px;
    }
  }
}

.h1-banner .breadcrumbs, #p-3378 .breadcrumbs, #p-1752 .breadcrumbs, #p-1754 .breadcrumbs, #p-1755 .breadcrumbs, #p-1753 .breadcrumbs, #p-1756 .breadcrumbs, #p-1758 .breadcrumbs, #p-1757 .breadcrumbs { // Hide breadcrumbs on homepages with banners
   @include visually-hidden;
}

 .h1-banner, #p-4898 h1, #p-3378 h1:not(.searchbox-header) {  // Hide h1 on homepages with banners
  @include visually-hidden;
}


/*
 * Fancy Splash Image (Jumbotron)
 * --------------------------------------------------
 */

.jumbotron {
  max-height: 50px;
  padding-top: 0px;
  margin-bottom: 0;
  background-color: $darkred; //fallback if all else fails
  background-image: url("../images/banner.gif"); //fallback if no image uploaded
  background-size: cover;
  h2 {
    color: #fff;
  }
  .container {
    padding: 0px;
  }
  @include respond-to(small) { 
    min-height: 75px;
    .container {
      position: relative;
      margin: 0px;
    }
  }
}

.overlaywrap {
  background-color: $trans-red; // Also applies to Law. Default if location is not specified
  color: #E7E5E2;
  left: 0;
  top: 0;
  h2 {
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 0;
    padding: 10px 15px;
    &:hover {
      text-decoration: none;
    }
  }
  &.crerar {
    background-color: $trans-blue;
  }
  &.eckhart {
    background-color: $trans-purple;
  }
  &.mansueto {
    background-color: $trans-darkgreen;
  }
  &.reg {
    background-color: $trans-lightgreen;
  }
  &.ssa {
    background-color: $trans-orange;
  }
  @include respond-to(small) {
    position: absolute;
    padding-right: 3em;
    padding-left: 1.7em;
    h2 {
      font-size: 1.6em;
      margin-top: 10px;
      padding: 0;
    }
  }
}

/*
 * Content
 * --------------------------------------------------
 */

.breadcrumbs {
  padding-top: 5px;
  padding-left: 20px;
  font-size: 85%;
 }


.centermain {
  padding: 0;
  max-width: 900px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  #p-3378 &, #p-1752 &, #p-1754 &, #p-1755 &, #p-1753 &, #p-1756 &, #p-1758 &, .floorplanpage &, .directory & {  // Exception for Home pages
    max-width: 1200px;
  }
  .collectionpage & {
    padding-top: 1.75em;
  }
  @include respond-to(small) {
    padding: 0 30px 0 15px;
  }
}

.search-results {
  & ul {
    list-style: none;
    padding-left: 0;
  }
  & h4 {
      margin: 20px 0 0 0;
      a {
        @extend h4;
        text-decoration: underline;
      }
  }
  & p {
    color: $darkgray;
    margin-bottom: 2px;
    font-style: italic;
  }
}

.empty-state {
  padding: 1em;
  color: $darkgray;
  .nothing {
    display: block;
    font-size: 1.3em;
    padding-left: 0;
  }
}


/*
 * Text
 * --------------------------------------------------
 */

#footnotes {
  font-family: 'ProximaNova-Light';
  font-size: 0.8em;
}

.block-pullquote {
  clear: both;
  padding: 2em 0;
}

.pullquote {
  border-left: 5px solid #c8c8c8;
  padding-left: 1em;
  margin-left: 1em;
}

blockquote {
  margin: 0 50px;
  padding: 0;
  text-indent: -2em;
  quotes: "\201C""\201D""\2018""\2019";
  line-height: 2.2em;
  border-left: 0px;
  &:before {
    color: $darkgray;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.1em;
    vertical-align: -0.4em;
  }
  &:after{
    color: $darkgray;
    content: close-quote;
    font-size: 4em;
    line-height: 0.5em;
    margin-left: 0.1em;
    vertical-align: -0.4em;
  }
  & p {
    display: inline;
  }
}

.citation {
  color: $darkgray;
  font-size: .9em;
  margin: 0 0 2em 4em;
}

.intro {
  font-size: 1.0625em;
  font-family: 'ProximaNova-Light'; }

.pullquote {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4375em; }

p q { //Inline Quote
  quotes: "\201C""\201D""\2018""\2019";
  &:before {
    content: open-quote;
    font-size: 1.2em;
  }
  &:after{
    content: close-quote;
    font-size: 1.2em;
  }
}


/*
 * Images, Video, and other Media
 * --------------------------------------------------
 */

.block-video > div { //override Wagtail's weird padding
    padding-bottom: 20px!important;
}

.block-image + div {
  margin-top: 1em;
}

img.richtext-image {  //images placed in richtext streamfield
  &.left {
    float: left;
    margin: 0 1.2em 0.7em 0;
    @include respond-to(small) {
      margin: 0 1.5em 0.7em 0;
    }
  }
  &.right {
    float: right;
    margin: 0 0 0.7em 1.2em;
    @include respond-to(small) {
      margin: 0 0 0.7em 1.5em;
    }
  }
  &.full-width {
    width: 100%;
    height: auto;
    margin: 1em 0;
  }
}

figure.imgcaption { //image in streamfield
  max-width: 100%;
  padding: 0;
  display:table; // keeps caption within img width 1/3
  &.pull-left {
    margin: 10px 20px 10px 0;
  }
  &.pull-right {
    margin: 10px 0 10px 20px;
  }
  figcaption {
    padding: 0.7em 1em;
    font-size: 0.8em;
    color: $mid-dark;
    display:table-caption; // keeps caption withing img width 2/3
    caption-side:bottom; // keeps caption withing img width 3/3
    background: $midneutral;
  }
}

span.img-title {
  font-style: italic;
  font-size: 1.1em;
}

span.img-caption {
  font-size: 1.1em;
  padding-top: 10px;
  display: block;
}

a.img-src {
  font-size: 0.8em;
}

.imgcaption .fullwidth {
  display: inline-block;
  margin: 15px 0;
}

figure.coll-thumb {
    margin-right: auto;
    margin-left: auto;
    max-width: 25em;
    margin-bottom: 1.5em;
    @include respond-to(small) {
        float: left!important;
        margin-right: 2em;
    }
}

// Solo, Duo, and Trio Image Stylings
.standardpage .collex-solo, .standardpage .collex-duo, .standardpage .collex-trio, .standardpage .collex-verso {
  img {
      border: 3px solid #c8c8c8;
  }
  .img-title {
    color: $mid-dark;
    font-size: $font-small;
    font-weight: 600;
  }
  .img-citation {
    color: $darkneutral;
    font-size: 0.95em;
  }
  .img-caption {
    color: #000;
    font-size: 1em;
  }
}

// Image Link Streamfield

.thumbnail-mod {
  text-align: center;
  img {
    margin-left: auto;
    margin-right: auto;
  }
  p {
    margin-top: 0.75em;
    font-size: 1.1em;
  }
}


/*
 * Lightbox Styling
 * --------------------------------------------------
 */

.modal-content {
  -webkit-box-shadow: 0 5px 35px rgba(0,0,0,.8);
  box-shadow: 0 5px 35px rgba(0,0,0,.8);
}

.modal-header {
    padding: 15px 20px 0px 15px;
    border-bottom: none;
    &> .close {
      font-size: 2em;
      opacity: .6;
      &:hover {
        opacity: .5;
      }
    }
}


/*
 * Carousel
 * --------------------------------------------------
 */

.carousel {
  margin-bottom: 20px;
}

.carousel-indicators {
    bottom: 0px;
}

.carousel-inner {
  &>.item>a>img, &>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
    width: 100%
  }
  .hero-image {
    img {
      @include respond-to(small) {
        max-width: 100%;
      }
    }
  }
  .hero-image::after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, rgba(0,0,0, 0) 0, #000 100%);
    margin-top: -175px;
    height: 175px;
    width: 100%;
    content: '';
  }
}

.carousel-caption {
  padding-top: 0;
  text-shadow: 1px 1px 30px rgba(0, 0, 0, 1.0);
  z-index: 5;
  padding-bottom: 25px;
  .image-title {
    font-size: 1.5em;
    display: block;
    @include respond-to(small) {
      font-size: 2em;
    }
  }
  .image-subtitle {
    font-size: 1.2em; 
    @include respond-to(small) {
      font-size: 1.5em;
    }
  }
  & a {
    color: #fff;
    line-height: 2.3em;
    &:hover {
      color: $darkgray;
      text-decoration: none;
    }
  }
}


/*
 * Homepage Modules / Widgets
 * --------------------------------------------------
 */


.studymod { //Study Spaces
  p {
    text-align: center;
  }
  a {
    color: $eckhartpurple;    
  }
  a:hover {
    text-decoration: none;
  }
  .material-icons, a.material-icons {
    color: $eckhartpurple;
    vertical-align: middle;
    font-size: 2em;
  }
  .fa, a.fa {  // to make-up for the size difference between the two icon families
    color: $eckhartpurple;
    font-size:1.5em;
    padding-top: 10px;
    padding-bottom:7px;
  }
}

.home-profile { // featured profile on specialist module
  padding: 0;
  max-width: 110px;
}

#widget-explore-research-guides {
  padding-left: 0;
}


/*
 * Workshops & Events Homepage Module
 * --------------------------------------------------
 */

.dates-bar {
  margin-bottom: 10px;
  a.active {
    color: #fff;
    background-color: $active-hover;
    .date, .month {
      color: #fff;
    }
  }
}

a#event-header {
  color: #000;
  font-weight: 100;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.event-date {
  color: $reggreen;
  font-weight: 600;
}

.btn-register {
  color: $active;
  background-color: transparent;
  padding: 5px 0 0 0!important;
  font-weight: 600;
  margin: 0;
  &:after {
    content: "\f274";
    font-family: FontAwesome;
    font-size: 1em;
    line-height: 0.1em;
    margin-left: 0.5em;
  }
  &:hover {
    color: $active-hover;     
  }
}



/*
 * News Homepage Module
 * --------------------------------------------------
 */

 .news-wrap {
  display: flex;
  flex-wrap: wrap;
  display: -webkit-flex;  // Safari fix
  -webkit-flex-wrap: wrap;  // Safari fix
}

.newsblock {
  padding: 0 5px;
  white-space: normal;
  h3 {
    font-style: normal;
    padding: 0.75em 0 0.25em;
  }
  p {
    clear:both;
    padding: 0 15px 0 0;
  }
}

figure.embed {
  width: 98%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  font-size: 0.9em;
  background: transparent;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .newsblock & {
    clear:both;
  }
  img {
    object-fit: cover;
    min-width: 100%;
    min-height: auto;
    height: 125px; // for centering the image
    width: 200px; // for centering the image
    display: block; // corrects inline gap in enclosing div
    width: auto\9; // ie8+9
    -ms-interpolation-mode: bicubic; // lt ie8
  }
  figcaption {  //category tags on news images
    width: 100%;
    padding: 0.5em;
    background: rgba(50,50,50,0.7);
    color: #fff;
    position: absolute;
    bottom: 0px;
    &.exhibitdate {
      position: absolute !important;
      bottom: 30px;
    }
    &.exhibits {
      background: rgb(37,25,15);
    }
    &.spaces {
      background: rgb(101,109,51);
    }
    &.people {
      background: rgb(153,83,36);
    }
    &.research {
      background: rgb(21,67,95);
    }
    &.resources {
      background: rgb(70,72,51);
    }
    &.teaching {
      background: rgb(21,95,131);
    }
    &.events {
      background: rgb(114,86,99);
    }
    &.hours-access {
      background: rgb(128,0,0);
    }
    &.spotlight {
      background: rgb(198,130,32);
    }
    &.from-the-director {
      background: rgb(143,57,49);
    }
    .newsblock & {
      position: inherit;
      font-size: 1.1em;
      font-weight: 600;
    }
  }
}

.figure-wrap {
  height: 125px;
  overflow: hidden;
}


/*
 * Buttons
 * --------------------------------------------------
 */

.btn {
  padding: 10px 14px;
}

.btn-default {
  color: #fff;
  background-color: $darkgray;
  padding: 6px 12px;
  margin-bottom: 10px;
  &:hover {
    background: $lightgray;
    color: $maroon; 
  }
}

.btn-primary {
  @extend .btn-morelink;
}

.btn-list-toggle {  //Behavior togglers with maroon lines
  color: $darkgray;
  background-color: #fff;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 5px 15px;
  border-radius: 0;
  &.active {
    border-top: 2px solid $maroon;
    border-bottom: 2px solid $maroon;
    color: $maroon;
    -webkit-box-shadow: none;
    box-shadow: none;   
    &:after {
      @extend .menu-marker;
    }
  }
  &:hover {
    border-top: 2px solid #d8b9b7;
    border-bottom: 2px solid #d8b9b7;
    color: $maroon;
    -webkit-box-shadow: none;
    box-shadow: none; 
  }
}

.btn-textonly { // Dropdowns - no borders, rtc
  color: $darkgray;
  background-color: transparent;
  border:0;
  padding: 5px 10px 5px 5px;
  border-radius: 0;
  margin-bottom: 0px;
  @include respond-to(small) {
    padding: 5px 10px 5px 10px;
  }
}

.btn-obvious { // testing for directory
  color: $mid-dark;
  background-color: #fff;
  border-color: #ccc;
  padding: 0.5em 1em; 
}

.open>.dropdown-toggle.btn-textonly:focus, .open>.dropdown-toggle.btn-textonly:hover {
  background-color: $hovercalm;
}

.btn-alpha {  //Finding Aids Search Page
  padding: 20px 0 0 0;
  text-align: center;
  @include respond-to(smallonly) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  a {
    color: #333;
    font-size: 0.8em;
    padding: 0.5em 1em;
    line-height: 3em;
    border-right: 1px solid #800000;
    &:last-child {
      border-right: none;
    }
    @include respond-to(smallonly) {
      flex: 1;
      width: 10%;
    }
    @include respond-to(medium) {
      padding: 0.5em 0.75em;
    }
  }
}

/*
 * Tables
 * --------------------------------------------------
 */

 // Table base (mobile friendly magic) is in global.scss

 .distinct-list { //table base
  a {
    color: #8a4a20;
  }
  .material-icons, .fa {
    padding-right: 10px;
    &.floorplan {
      font-size: 1.1em;
      padding-right: 5px;
    }
  }
  &>tbody>tr {
    &:last-child {
      border-bottom: 2px solid $table-main;
    }
  }
  @include respond-to(medium) {
    margin-top: 0;
  }
}

thead a {
  color: #000036;
  font-weight: 400;
  text-decoration: underline;
}

tbody a {
  color: #32327f;
  text-decoration: underline;
}

.etable {
  a[href*="events.uchicago"] {
    font-size: 1.05em;
    font-weight: 600;
    a[href*=register] {
      color: red;
    }
    &:after {
      content: "\0020\F08E";
      font-family: FontAwesome;
      font-size: 0.8em;
      padding-left: 0.5em;
      display: inline-block;
    }
  }
}

tr.etable-header>th, th.etable-header, table.etable thead {
  border-top: none!important;
  border-bottom: 2px solid $table-main;
  font-family: $accent-font;
  font-size: 1.1em;
  color: darken($table-main, 27%);
  font-weight: 600;
  background: lighten($table-main, 35%)!important;
  @include respond-to(smallonly) {
    display: inline-block;
    width: inherit;
  }
}

table.etable>thead>tr>th {
  border-bottom: 0;
}

.coll-list.table>thead>tr>th {
  border-bottom-color: $table-main;
}

table.etable>tbody>tr {
  &:last-child {
    border-bottom: 2px solid $table-main;
  }
  &>td:last-child {
    vertical-align:middle;
    white-space:nowrap;
    @include respond-to(medium) {
      padding-right: 10px;
    }
    @include respond-to(large) {
      padding-right: 10px;
    }
  }
}

.quicktable { // "Quick reference" on top of direcotry table/list
  margin: 10px 0 0;
  &>tbody>tr {
    @extend .currentlist;
    &>td:first-child {
      color:$maroon;
      font-weight:600;
    }
  }
}

.headline { // Headers for "Browse" and "Limit" dropdowns and toggles
  color:$maroon;
  font-weight:600;
}

.scrc-list {
  @extend .distinct-list;
  margin-top: 1em;
  @include respond-to(small) {
    &>tbody>tr>td:first-child {
        width: 30%;
        padding: 15px 30px;
    }
    &>tbody>tr>td:nth-child(2) {
        padding-left: 15px;
    }
  }
}

.block-staff_listing {
  ul {
    padding-left: 0;
  }
  li {
    line-height: 2em;
    list-style: none;
  }
}

//East Asia table

#p-3765 .table { 
  @include respond-to(small) {
     width: 25em;
  }
  &>tbody>tr>td {
    @include respond-to(small) {
      width: 50%;
    }
  }
}

#p-3765 .table>tbody>tr>td:first-child, #p-3765 .table>thead>tr>th:first-child {
  @include respond-to(small) {
    padding-right: 3em;
    text-align: right;
  }
}


/*
 * Mini Search (For Browse / Lists / Tables)
 * --------------------------------------------------
 */

.searchbox{
  position:relative;
  min-width:30px;
  margin:5px 0 10px 0;
  width: 100%;
  height:30px;
  float:right;
  @include respond-to(small) {
    margin:15px 0 10px 0;
  }
}

.searchbox-input{
    top:0;
    right:0;
    border:0;
    outline:0;
    background:#dcddd8;
    width:100%;
    height:30px;
    margin:0;
    padding:0px 55px 0px 20px;
    font-size:1em;
    color:$maroon;
}

.searchbox-icon,
.searchbox-submit,
.searchbox > input[type="submit"] {
    content: "\f002";
    width:30px;
    height:30px;
    display:block;
    position:absolute;
    top:0;
    font-family:verdana;
    font-size:1em;
    right:0;
    padding:0;
    margin:0;
    border:0;
    outline:0;
    line-height:30px;
    text-align:center;
    cursor:pointer;
    color:#ddd;
    background:$maroon;
}

.byline{
  position:absolute;
  top:150px;
  left:30%;
  text-align:center;
  font-size:18px;
}
.byline a{
  text-decoration:none;
  color: $maroon;
}

.distinct-search {
  padding: 15px 30px;
  background-color: rgba(210, 205, 204, 0.5);
  border-top: 3px solid #D6D6CE;
  & input.searchbox-input {
    background-color: #fff;
    border: 1px solid #ccc;
  }
  @include respond-to(smallonly) {
    max-width: 90%;
    margin: 0 15px;
  }
}



/*
 * Ask A Librarian Pages
 * --------------------------------------------------
 */

 //Ask a Librarian menu item from global nave is in "navigation.scss"

.libraryh3lp {
  iframe {
    width: 100%;
    height: 400px;
    border: 0px;
    @include respond-to(small) {
      width: 80%;
    }
  }
}

.ask-rightside {
  @extend .distinct-rightside;
  background-color: #fff;
  @include respond-to(medium) {
    background-color: $hovercalmlight;
    border-left: none!important;
    margin-top: 10px;
  }
  ul {
    text-indent: 0;
    margin-left: 0;
    padding-left: 5px;
  }
  i {
    font-size: 1.5em;
    margin: 0.3em 0;
  }
  hr {
    border-top: 1px dotted $brightpurple;
  }
}

table.ask {
  @extend .distinct-list;
  a {
    color: $brightpurple;
  }
  &>tbody>tr>td {
    line-height: 1.8em;
    &:last-child { // Targeting "ask button" cells
      text-align: center;
    }
  }
}

a.btn-ask {
  color: #fff!important;
  background-color: $darkgray;
  border-right: 0px;
  padding: 10px 20px;
  margin: 10px 0;
  text-decoration: none;
  &:before {
    content: "\f0e5";
    font-family: FontAwesome;
    font-size: 1.2em;
    margin-right: 0.5em;
 }
  &:after {
    content: "Chat Offline";
  }
  &.active {
    background-color: $active;
    &:hover {
      background-color: $reggreen;     
    }
    &:before {
      content: "\f075";
    }
    &:after {
      content: "Chat Now";
    }
  }
  &:hover {
    background-color: $darkneutral;     
  }
}


/*
 * Hours (Mods to LibCal Feed)
 * --------------------------------------------------
 */

// ** Springshare requires us to have two different widgets for mobile and desktop.  ** //


// >> Desktop Widget << //

.s-lc-whw {
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 100%;
  background-color: transparent;
  border-bottom: none;
  border-left: none;
  border-collapse: collapse;
  border-spacing: 0;
  & thead {
    background-color: lighten($table-main, 15%);;
    color: #323232;
    font-style: italic;
    &>tr>th {
      vertical-align: bottom;
      border-bottom: 2px solid $table-main;
      padding: 5px;
    }
  }
  & th {
    border-top: none;
    border-bottom: none;
    border-right: none;
  }
  &>tbody>tr {
    &:hover {
      background-color: unset; // Removes hover color on table row that is not clickable
    }
    &>td {
      padding: 5px;
      &:first-child {
        border-right: 1px solid #c5c8a4;
        @include respond-to(medium) {
          min-width: 150px;
        }
      }
      &.s-lc-whw-locname {
        font-weight: bold;
      }
    }
    &:nth-of-type(even) {
      background-color: #f9f9f9;
    }
    &:last-child {
      border-bottom:2px solid $table-main;
    }
  }
}

// Highlights "Today" column
.s-lc-whw-head-date {
  color: #4c4c4c;
  font-size: 0.9em;
}

.s-lc-whw-today-h, .s-lc-whw-today-h>.s-lc-whw-head-date {
  color: #000;
  background-color: $table-main;
  font-style: normal;
}

.s-lc-whw-today {
  background-color: lighten($table-main, 32%);
  font-weight: 600;
}

.hours-today tr {
  border-bottom: 1px solid #b8aab1;
  & td {
    width: 100%;
    display: list-item;
    list-style: none;
    &:first-child {
      color: $active-hover;
      padding: 5px 0 3px 0;
    }
    &:last-child {
      padding: 0 0 5px 0;
    }
  }
}


// >> Mobile "Today" Widget  << //
// A little wonky to work with Springshare's code

.s-lc-w-today {
  width: 100%;
  margin-bottom: 20px;
    & thead {
    background-color: lighten($table-main, 15%);
    color: #323232;
    font-style: italic;
    &>tr>th {
      vertical-align: middle;
      border-bottom: 2px solid $table-main;
      padding: 5px;
      text-align: center!important;
      font-size: 0.9em;
      line-height: 2em;
    }
    .s-lc-w-head-pre {
      display: none;
    }
  }
  .s-lc-w-location {
    font-weight: 600;
  }
  .s-lc-w-previous, .s-lc-w-next {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    text-indent: -9999px;  // Swapping out button text, part 1
    line-height: 0;   // Swapping out button text, part 2
    &:disabled {
      background-color: #efefef;
    }
  }
  .s-lc-w-previous {
    margin-left: 1em;
    float: left;
    &:after {   // Swapping out button text, part 3a
      content: "\00ab";
      text-indent: 0;
      display: block;
      line-height: initial;
    }
  }
  .s-lc-w-next {   // Swapping out button text, part 3b
    margin-right:1em;
    float: right;
    &:after {
      content: "\00bb";
      text-indent: 0;
      display: block;
      line-height: initial;
    }
  }
  td {
    padding: 5px;
    &:first-child {
      border-right: 1px solid #c5c8a4;
    }
    &.s-lc-w-time {
      padding-left: 15px;
    }
  }
  tr {
    border: 1px solid #ddd;
    &:nth-of-type(even) {
      background-color: #f9f9f9;
    }
    &:last-child {
      border-bottom:2px solid $table-main;
    }
  }
}

.s-lc-w-today-view-all {
    display: none;
}

.s-lc-whw-sublocname {
  padding-left: 10px!important;
}

.s-lc-whw-footnote {
  color: #555;
  font-size: 80%;
  & td:hover {
    background-color:#fff!important;
  }
}

.s-lc-whw-pr, .s-lc-whw-ne {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
}

.s-lc-whw-pr:disabled, .s-lc-whw-ne:disabled {
  background-color: #efefef;
}


.sr-only, .s-lc-w-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}


/*
 * Agenda (Conferences)
 * --------------------------------------------------
 */

.agenda {
  padding: 0;
}

.agenda-header {
  color: #000;
  font-size: 1.1em;
  font-weight: 600;
}

.end-time {
 padding-right: 10px;
 &:before {
   content: "\2013\0020";
 }
}

.agenda-body {
  padding: 0;
  &>.row {
    padding-top: 10px;
  }
}

.location {
  padding: 0;
  color: $darkgray;
  font-size: 1.1em;
  font-style: italic;
  font-weight: 600;
}

.session-title {
  padding-left: 15px;
  @include respond-to(small) {
    padding-left: 0;
    margin-left: -10px;
  }

}

.session-subtitle {
  color: $darkgray;
  font-size: 1.1em;
  font-weight: 600;
  padding-right: 20px;
}

.agenda-body {
  padding-left: 2.2em;
  @include respond-to(small) {
    padding-left: 0;
  }
}

.agenda-presenters {
  color: #635D5A;
  font-size: 1em;
  font-weight: 600;
  padding-right: 20px;
}


/*
 * Event Calendar List
 * --------------------------------------------------
 */

td.time, td.event-nav {
    white-space: nowrap;
}

.event-nav {
  padding-bottom: 15px;
  @include respond-to(small) {
    text-align: right;
  }
  & a {
    color: $darkgray;
  }
}

.event-time {
  font-weight: 400;
  display: block;
  font-style: italic;
}


/*
 * Spaces
 * --------------------------------------------------
 */

.featured {
  color: $mansuetogreen;
  font-size: 1.1em;
  & a {
    color: $reggreen;
  }
  .material-icons, .fa {
    padding: 0 3px 0 15px;
    font-size: 1.2em;
    color: $active;
    a {
      color: $active;
    }
  }
}

.spaces-dropdown {
  padding-top: 15px;
}

.spaces-toggle {
    padding: 15px 0;
}

.img-spaces { // Div holding photos in individual space pages
  padding: 0 10px 0 0;
  img {
    margin-bottom: 15px;
  }
}

.spacelist {
  @extend .distinct-list;
  @include respond-to(small) {
    &>tbody>tr>td {
      &:nth-child(2), &:nth-child(3) {
        width: 25%;
      }
      &:last-child {
        padding-right: 15px;
      }
    }
  }
  img {
    max-width: 150px;
    @include respond-to(small) {
      max-width: 100px;
    }
    @include respond-to(medium) {
      max-width: 200px;
    }
  }
}

.space-computers { // computers available
  font-weight: 600;
  color: $reggreen;
  line-height: 3em;
}

.features-list {
  line-height: 2em;
  list-style: none;
}

.locations-list {
  border-bottom: 1px solid #b8aab1;
  &>div {
    padding: 0 1em 0 0;
  }
  img {
    margin-bottom: 1em;
  }
}

.btn-reserve {  //Reserve a room link
  color: #fff!important;
  letter-spacing: 0.1em;
  background-color: #58593F;
  border-right: 0px;
  margin: 5px 0 15px;
  padding: 5px 20px;
  &:before {
      color: #fff;
      content: "\f271";
      font-family: FontAwesome;
      font-size: 1.2em;
      line-height: 0.1em;
      margin-right: 0.5em;
    }
  &:hover {
      color: #fff;
      background-color: $active-hover;
  }
}

/*
 * Collections & Exhibits Listing
 * --------------------------------------------------
 */

.listings-dropdown {
  margin-top: 0;
  background-color: $hovercalm;
  min-width: 200px;
  color: #000;
  border-radius: 0;
  &>ul>li>a {
    color: #262626;
    font-size: 1em;
    &:hover {
      color: $darkgray;
      text-decoration: none;
      background-color: $hovercalmlight;
    }
  }
}

.coll-dropdown {
  margin-bottom: 15px;
  @include respond-to(medium) {
    padding: 0px 14px 10px 14px;
  }
}

.coll-checkbox {
padding: 10px 0px 0px 15px;
  @include respond-to(small) {
    padding: 5px 10px 5px 10px;
  }
}

.btn-morecoll {
  @extend .btn-morelink;
  background: $hovercalm;
  border-color: #a0a282;
}

.btn-coll-link {
  font-size: 0.9em;
  background: transparent;
  border: 1px solid #a0a282;
  border-radius: 0;
  padding: 6px 12px;
  margin-bottom: 20px;
}

.coll-list {
  @extend .distinct-list;
  @include respond-to(small) {
    & img {
      max-width: 100px;
    }
    &>tbody>tr>td:nth-child(2) {
        padding-left: 15px;
    }
    &>tbody>tr>td:nth-child(3), &>tbody>tr>td:nth-child(4) {
        width: 25%;
    }
  }
}

.currentlist {
  background-color: $hovercalm;
  &:nth-of-type(odd) {
      background-color: $hovercalmlight!important;
  }
}

tr.seealso {
  background-color: #eeeeeb!important;
  td {
    font-style: italic;
  }
}

p.acknowledgments {
  font-style: italic;
  color: $mansuetogreen;
  font-size: 1.1em;
}

a.coll-access {
  &:after {
    content: "\0020\f101";
    font-family: FontAwesome;
  }
}

/*
 * Directory Listing
 * --------------------------------------------------
 */

 .directory-list {
  @extend .distinct-list;
  & img {
    max-width: 7em;
  }
  &>tbody>tr>td {
    &:nth-child(2) {
      padding-left: 15px;
    }
    @include respond-to(small) {
      &:nth-child(3), &:nth-child(4) {
        width: 25%;
      }
    }
    @include respond-to(smallonly) {
      padding-left: 15px;
    }
  }
 }

.guide-link {
  line-height:2.2em;
  &:before {
    content: "\f0f6";
    font-family: FontAwesome;
    font-size: 1em;
    margin-right: 0.5em;
  }
}

.list-view, .coll-limit { // "Switch to [...] view"
  padding: 5px 15px;
  @include respond-to(small) {
    text-align: right;
    padding: 5px 15px 10px 0;
  }
  a {
    color: $darkgray;
  }
}

.sdir {
  margin: 2em 0 0.25em 0;
  padding-left: 0;
  h2 {
    font-family: "Cormorant Garamond", serif;
    color: $reggreen;
    font-size: 1.3em;
    padding-left: 15px;
    margin-right: 2em;
    margin-bottom: 0.25em;
    border-bottom: 2px solid #ADB17D;
      a, a:active, a:focus, a:hover {
      color: $mid-dark;
      text-decoration: none;
    }
  }
  article {
    @include respond-to(medium) {
      display: grid;
      grid-template-columns: 10% 40% 25% 25%;
      grid-gap: 1.5em;
    }
    h3 {
      margin-top: 0;
    }
    span[role=main] {
      font-weight: 600;
      display: block;
    }
  }
}

.sdir-wrap {
  @extend .news-wrap;
  padding: 0;
  h3 {
    font-size: 1.1em;
    margin-bottom: 0;
  }
  a, a:active, a:focus, a:hover {
    color: $crerarblue;
    text-decoration: underline;
  }
  .col-xs-12 {
    padding-bottom: 1em;
    margin-top: 1em;
    @include respond-to(medium) {
      &:nth-child(3n+2) {
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;
      }
    }
  }
}

/*
 * Staff Profile Page
 * --------------------------------------------------
 */

.staff-profile { //Staff Page, Photo
  @include respond-to(smallonly) {
    padding: 0 3em 1.2em 3em;
  }
}

body.staffpublicpage {
  h1 {
    border-bottom: none;
    &~h2 {
      border-bottom: 1px solid #D6D6CE;
      margin-top: 0px;
      padding-bottom: 5px;
      font-weight: 400;
    }
  }
}



