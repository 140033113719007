/*------------------------------------------------------------------

> Styles can be used on public site and third party platforms <

[Table of contents]

Top Right Navbar (time/account/ask)
Main Navbar
Navigation Drop Downs

-------------------------------------------------------------------*/


@import "variables.scss";
@import "media.scss";



/*
 * Top Right Navbar (time/account/ask)
 * --------------------------------------------------
 */

.mobile-topnav {
  text-align: center;
  border-bottom: 1px solid $darkgray;
  background-color: rgba(67, 67, 67, 0.7);
  padding: 0.5em 0;
      a {
      color: #fff;
      padding: 0 1.5em;
  }
}

#navbar-right {
  font-size: 1em;
  padding-right: 1.5em;
  &>li>a {
      color: #f9f9f9;
      &:hover {
      	color:#E4E4E4;
        background-color: $transparent;
      }
      &:focus {
        background-color: $transparent;
      }
    }
  &>li>.dropdown-menu {
      color: #fff;
      text-align: right;
      background-color: $transparent;
  }
  @include respond-to(xlarge) {
      padding-right: 15em;
      margin-right: 30px;
  }
}

ul#navbar-right li:hover > ul.dropdown-menu.menuhover {
    display: block;
}

i.ask-icon {
  color:$darkgray;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
  &.active {
    color: #eee;
    text-shadow: none;
    -webkit-text-stroke-width: 0;
  }
}


/*
 * Main Navbar
 * --------------------------------------------------
 */

.fluid-top {
  box-shadow: 0 1px 7px #462b28; // Puts drop shadow on alert banners, if active
  @include respond-to(small) {
    padding: 0px;
  }
}

.navbar-brand {
  max-width: 175px;
  padding: 5px 15px 15px 15px;
  @include respond-to(small) {
    max-width: 225px;
    padding-left: 35px;
    padding-top: 5px;
  }
  @include respond-to(medium) {
    padding-top: 10px;
  }
}

.navbar-default {
  border: 0px;
  margin-bottom:0px;
  background-image: url("../images/warm_grey_reddish.jpg");
  background-repeat: repeat;
  background-color: $darkgray; //back-up
  z-index: 10;
  border-radius: 0;
  .navbar-nav {
    &>li>a {
      color: #eee;
      &:hover {
        color: #fff;
        background-color: $transparent;
      }
      &:focus {
        color: #fff;
      }
    }
    & .open .dropdown-menu {
      background-color: $transparent;
    }
    &>.open>a:focus {
      background-color: $maroon;
      color: #fff;
    }
    &>.open>a, &>.open>a:hover {
      color: #E4E4E4;
      background-color: $transparent;
    }
  }
  .divider {
      background-color: $darkgray;
  }
}

#global-navbar {
  background-color: rgba(67, 67, 67, 0.4);
  font-size: $font-small;
  padding-left:0.5em;
  &.navbar-nav>li>a:focus {
    color: #fff;
  }
  &.navbar-nav>.open>a:focus {
    background-color: $maroon;
  }
  @include respond-to(small)  {
    box-shadow: 0 1px 7px #462b28;
    margin-top: 70px;
    .navbar-nav>li>a {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}


/*
 * Navigation Drop Downs
 * --------------------------------------------------
 */

#navbar-right .dropdown-menu {
  border: none;
  &>li>a {
      color: #fff;
      font-size: 1.05em;
      margin-bottom: 5px;
    &:hover { //For top right nav
        color: $hoveraccent;
        text-decoration: none;
        background-color: transparent;
    }
  }
}


/* Not currently using, but saved for when nav changes */
// .dropdown-menu-right {
//   @include respond-to(medium) {
//     right: auto;
//     left: 0;
//   }
// }

.twocol-head { // Headers for mega drop downs
  color: #e7d5d3;
  font-size: .8em;
  line-height: 2.4em;
  text-transform: uppercase;
}

.twocol-drop {
  background-color: $transparent;
  @include respond-to(small) {
      min-width: 500px;
      padding: 10px 0 10px 0;
  }
  >li>ul>li {
    margin-bottom: 10px;
    a {
      color: #fff;
      font-size: $font-small;
      text-decoration: none;
      &:hover {
        color: $hoveraccent;
      }
    }
  }
}

.onecol-drop {
  background-color: $transparent;
  padding: 0 15px 10px 15px;
  @include respond-to(small) {
    min-width: 15em;
    padding: 10px 15px 10px 15px;
  }
  >li {
    margin-bottom: 5px;
    a {
      color: #fff!important;
      padding: 0!important;
      text-decoration: none;
      font-size: $font-small;
      margin-bottom: 12px;
      &:hover {
        color: $hoveraccent!important;
        background-color: transparent!important;
      }
    }
  }
  .twocol-head {
    padding-left: 0px;
  }
}

.ul-left {
  padding-bottom: 1.5em;
  @include respond-to(small) {
    padding-bottom: 0px;
    border-right: 1px solid $darkgray;
  }
}


